var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.GET_USER_DATA)?_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.createContract()}}},[_c('hr',{staticClass:"line__hr"}),_c('h3',{staticClass:"mb-10"},[_vm._v("Заполнение приложение №1")]),_c('div',{staticClass:"agreement__line"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-lg-3"},[(!_vm.lang_type)?_c('div',{staticClass:"agreement__item--title"},[_vm._v("ФИО,инициалы")]):_c('div',{staticClass:"agreement__item--title"},[_vm._v("ФИО,инициалы (на казахском)")])]),_c('div',{staticClass:"col-lg-9"},[_c('div',{staticClass:"agreement__item--input"},[(!_vm.lang_type)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.attachment_1.client_fio),expression:"attachment_1.client_fio"}],class:{
                    input__border:
                      _vm.messages &&
                      typeof _vm.messages['attachments.attachment_1.client_fio'] !==
                        'undefined',
                  },attrs:{"type":"text","required":"","name":"","placeholder":"ФИО,инициалы"},domProps:{"value":(_vm.attachment_1.client_fio)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.attachment_1, "client_fio", $event.target.value)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.attachment_1.client_fio_kz),expression:"attachment_1.client_fio_kz"}],class:{
                    input__border:
                      _vm.messages &&
                      typeof _vm.messages['attachments.attachment_1.client_fio_kz'] !==
                        'undefined',
                  },attrs:{"type":"text","required":"","placeholder":"ФИО,инициалы (на казахском)"},domProps:{"value":(_vm.attachment_1.client_fio_kz)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.attachment_1, "client_fio_kz", $event.target.value)}}})]),(
                  _vm.messages &&
                  typeof _vm.messages['attachments.attachment_1.client_fio'] !==
                    'undefined' &&
                  !_vm.lang_type
                )?_c('div',{staticStyle:{"padding":"5px 15px","float":"left","color":"red"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.messages["attachments.attachment_1.client_fio"]))+" ")]):_vm._e(),(
                  _vm.messages &&
                  typeof _vm.messages['attachments.attachment_1.client_fio_kz'] !==
                    'undefined' &&
                  _vm.lang_type
                )?_c('div',{staticStyle:{"padding":"5px 15px","float":"left","color":"red"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.messages["attachments.attachment_1.client_fio_kz"]))+" ")]):_vm._e()])])])])]),_c('div',{staticClass:"agreement__line"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-lg-3"},[(!_vm.lang_type)?_c('div',{staticClass:"agreement__item--title"},[_vm._v(" Действующего на основании ")]):_c('div',{staticClass:"agreement__item--title"},[_vm._v(" Действующего на основании (на казахском) ")])]),_c('div',{staticClass:"col-lg-9"},[_c('div',{staticClass:"agreement__item--input"},[(!_vm.lang_type)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.attachment_1.client_based_on),expression:"attachment_1.client_based_on"}],class:{
                    input__border:
                      _vm.messages &&
                      typeof _vm.messages['attachments.attachment_1.client_based_on'] !==
                        'undefined',
                  },attrs:{"type":"text","required":"","name":"","placeholder":"Действующего на основании"},domProps:{"value":(_vm.attachment_1.client_based_on)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.attachment_1, "client_based_on", $event.target.value)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.attachment_1.client_based_on_kz),expression:"attachment_1.client_based_on_kz"}],class:{
                    input__border:
                      _vm.messages &&
                      typeof _vm.messages['attachments.attachment_1.client_based_on_kz'] !==
                        'undefined',
                  },attrs:{"type":"text","required":"","name":"","placeholder":"Действующего на основании (на казахском)"},domProps:{"value":(_vm.attachment_1.client_based_on_kz)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.attachment_1, "client_based_on_kz", $event.target.value)}}})]),(
                  _vm.messages &&
                  typeof _vm.messages['attachments.attachment_1.client_based_on'] !==
                    'undefined' &&
                  !_vm.lang_type
                )?_c('div',{staticStyle:{"padding":"5px 15px","float":"left","color":"red"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.messages["attachments.attachment_1.client_based_on"]))+" ")]):_vm._e(),(
                  _vm.messages &&
                  typeof _vm.messages['attachments.attachment_1.client_based_on_kz'] !==
                    'undefined' &&
                  _vm.lang_type
                )?_c('div',{staticStyle:{"padding":"5px 15px","float":"left","color":"red"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.messages["attachments.attachment_1.client_based_on_kz"]))+" ")]):_vm._e()])])])])]),_c('div',{staticClass:"agreement__line"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-lg-3"},[(!_vm.lang_type)?_c('div',{staticClass:"agreement__item--title"},[_vm._v(" Юридический и фактический адрес ")]):_c('div',{staticClass:"agreement__item--title"},[_vm._v(" Юридический и фактический адрес (на казахском) ")])]),_c('div',{staticClass:"col-lg-9"},[_c('div',{staticClass:"agreement__item--input"},[(!_vm.lang_type)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.attachment_1.client_address),expression:"attachment_1.client_address"}],class:{
                    input__border:
                      _vm.messages &&
                      typeof _vm.messages['attachments.attachment_1.client_address'] !==
                        'undefined',
                  },attrs:{"type":"text","placeholder":"Юридический и фактический адрес"},domProps:{"value":(_vm.attachment_1.client_address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.attachment_1, "client_address", $event.target.value)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.attachment_1.client_address_kz),expression:"attachment_1.client_address_kz"}],class:{
                    input__border:
                      _vm.messages &&
                      typeof _vm.messages['attachments.attachment_1.client_address_kz'] !==
                        'undefined',
                  },attrs:{"type":"text","placeholder":"Юридический и фактический адрес (на казахском)"},domProps:{"value":(_vm.attachment_1.client_address_kz)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.attachment_1, "client_address_kz", $event.target.value)}}})]),(
                  _vm.messages &&
                  typeof _vm.messages['attachments.attachment_1.client_address'] !==
                    'undefined' &&
                  !_vm.lang_type
                )?_c('div',{staticStyle:{"padding":"5px 15px","float":"left","color":"red"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.messages["attachments.attachment_1.client_address"]))+" ")]):_vm._e(),(
                  _vm.messages &&
                  typeof _vm.messages['attachments.attachment_1.client_address_kz'] !==
                    'undefined' &&
                  _vm.lang_type
                )?_c('div',{staticStyle:{"padding":"5px 15px","float":"left","color":"red"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.messages["attachments.attachment_1.client_address_kz"]))+" ")]):_vm._e()])])])])]),_c('div',{staticClass:"agreement__line"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"row align-items-center"},[_vm._m(0),_c('div',{staticClass:"col-lg-9"},[_c('div',{staticClass:"agreement__item--input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.attachment_1.client_postcode),expression:"attachment_1.client_postcode"}],class:{
                    input__border:
                      _vm.messages &&
                      typeof _vm.messages['attachments.attachment_1.client_postcode'] !==
                        'undefined',
                  },attrs:{"type":"text","required":"","name":"","placeholder":"Почтовый индекс"},domProps:{"value":(_vm.attachment_1.client_postcode)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.attachment_1, "client_postcode", $event.target.value)}}})]),(
                  _vm.messages &&
                  typeof _vm.messages['attachments.attachment_1.client_postcode'] !==
                    'undefined'
                )?_c('div',{staticStyle:{"padding":"5px 15px","float":"left","color":"red"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.messages["attachments.attachment_1.client_postcode"]))+" ")]):_vm._e()])])])])]),_c('div',{staticClass:"agreement__line"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"row align-items-center"},[_vm._m(1),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"agreement__item--input"},[_c('input',{directives:[{name:"mask",rawName:"v-mask",value:({mask: '+7 (999) 999 99 99'}),expression:"{mask: '+7 (999) 999 99 99'}"},{name:"model",rawName:"v-model",value:(_vm.attachment_1.client_first_phone_number),expression:"attachment_1.client_first_phone_number"}],class:{
                    input__border:
                      _vm.messages &&
                      typeof _vm.messages[
                        'attachments.attachment_1.client_first_phone_number'
                      ] !== 'undefined',
                  },attrs:{"type":"text","required":"","placeholder":"Контактный телефон первичного контакта"},domProps:{"value":(_vm.attachment_1.client_first_phone_number)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.attachment_1, "client_first_phone_number", $event.target.value)}}})]),(
                  _vm.messages &&
                  typeof _vm.messages[
                    'attachments.attachment_1.client_first_phone_number'
                  ] !== 'undefined'
                )?_c('div',{staticStyle:{"padding":"5px 15px","float":"left","color":"red"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.messages["attachments.attachment_1.client_first_phone_number"]))+" ")]):_vm._e()])])]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"row align-items-center"},[_vm._m(2),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"agreement__item--input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.attachment_1.client_first_email),expression:"attachment_1.client_first_email"}],class:{
                    input__border:
                      _vm.messages &&
                      typeof _vm.messages['attachments.attachment_1.client_first_email'] !==
                        'undefined',
                  },attrs:{"type":"email","required":"","name":"","placeholder":"E-mail первичного контакта"},domProps:{"value":(_vm.attachment_1.client_first_email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.attachment_1, "client_first_email", $event.target.value)}}})]),(
                  _vm.messages &&
                  typeof _vm.messages['attachments.attachment_1.client_first_email'] !==
                    'undefined'
                )?_c('div',{staticStyle:{"padding":"5px 15px","float":"left","color":"red"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.messages["attachments.attachment_1.client_first_email"]))+" ")]):_vm._e()])])])])]),_c('div',{staticClass:"agreement__line"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"row align-items-center"},[_vm._m(3),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"agreement__item--input"},[_c('input',{directives:[{name:"mask",rawName:"v-mask",value:({mask: '+7 (999) 999 99 99'}),expression:"{mask: '+7 (999) 999 99 99'}"},{name:"model",rawName:"v-model",value:(_vm.attachment_1.client_second_phone_number),expression:"attachment_1.client_second_phone_number"}],class:{
                    input__border:
                      _vm.messages &&
                      typeof _vm.messages[
                        'attachments.attachment_1.client_second_phone_number'
                      ] !== 'undefined',
                  },attrs:{"type":"text","required":"","name":"","placeholder":"Контактный телефон вторичного контакта"},domProps:{"value":(_vm.attachment_1.client_second_phone_number)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.attachment_1, "client_second_phone_number", $event.target.value)}}})]),(
                  _vm.messages &&
                  typeof _vm.messages[
                    'attachments.attachment_1.client_second_phone_number'
                  ] !== 'undefined'
                )?_c('div',{staticStyle:{"padding":"5px 15px","float":"left","color":"red"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.messages["attachments.attachment_1.client_second_phone_number"]))+" ")]):_vm._e()])])]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"row align-items-center"},[_vm._m(4),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"agreement__item--input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.attachment_1.client_second_email),expression:"attachment_1.client_second_email"}],class:{
                    input__border:
                      _vm.messages &&
                      typeof _vm.messages['attachments.attachment_1.client_second_email'] !==
                        'undefined',
                  },attrs:{"type":"text","required":"","name":"","placeholder":"E-mail вторичного контакта"},domProps:{"value":(_vm.attachment_1.client_second_email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.attachment_1, "client_second_email", $event.target.value)}}})]),(
                  _vm.messages &&
                  typeof _vm.messages['attachments.attachment_1.client_second_email'] !==
                    'undefined'
                )?_c('div',{staticStyle:{"padding":"5px 15px","float":"left","color":"red"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.messages["attachments.attachment_1.client_second_email"]))+" ")]):_vm._e()])])])])]),_c('div',{staticClass:"agreement__line"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"row align-items-center"},[_vm._m(5),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"agreement__item--input"},[_c('input',{directives:[{name:"mask",rawName:"v-mask",value:({mask: '+7 (999) 999 99 99'}),expression:"{mask: '+7 (999) 999 99 99'}"},{name:"model",rawName:"v-model",value:(_vm.attachment_1.client_first_fax),expression:"attachment_1.client_first_fax"}],class:{
                    input__border:
                      _vm.messages &&
                      typeof _vm.messages['attachments.attachment_1.client_first_fax'] !==
                        'undefined',
                  },attrs:{"type":"text","required":"","name":"","placeholder":"Факс (первый)"},domProps:{"value":(_vm.attachment_1.client_first_fax)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.attachment_1, "client_first_fax", $event.target.value)}}})]),(
                  _vm.messages &&
                  typeof _vm.messages['attachments.attachment_1.client_first_fax'] !==
                    'undefined'
                )?_c('div',{staticStyle:{"padding":"5px 15px","float":"left","color":"red"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.messages["attachments.attachment_1.client_first_fax"]))+" ")]):_vm._e()])])]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"row align-items-center"},[_vm._m(6),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"agreement__item--input"},[_c('input',{directives:[{name:"mask",rawName:"v-mask",value:({mask: '+7 (999) 999 99 99'}),expression:"{mask: '+7 (999) 999 99 99'}"},{name:"model",rawName:"v-model",value:(_vm.attachment_1.client_second_fax),expression:"attachment_1.client_second_fax"}],class:{
                    input__border:
                      _vm.messages &&
                      typeof _vm.messages['attachments.attachment_1.client_second_fax'] !==
                        'undefined',
                  },attrs:{"type":"text","required":"","name":"","placeholder":"Факс (второй)"},domProps:{"value":(_vm.attachment_1.client_second_fax)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.attachment_1, "client_second_fax", $event.target.value)}}})]),(
                  _vm.messages &&
                  typeof _vm.messages['attachments.attachment_1.client_second_fax'] !==
                    'undefined'
                )?_c('div',{staticStyle:{"padding":"5px 15px","float":"left","color":"red"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.messages["attachments.attachment_1.client_second_fax"]))+" ")]):_vm._e()])])])])]),_c('div',{staticClass:"agreement__line"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-lg-3"},[(!_vm.lang_type)?_c('div',{staticClass:"agreement__item--title"},[_vm._v(" Наименование организации ")]):_c('div',{staticClass:"agreement__item--title"},[_vm._v(" Наименование организации (на казахском) ")])]),_c('div',{staticClass:"col-lg-9"},[_c('div',{staticClass:"agreement__item--input"},[(!_vm.lang_type)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.attachment_1.client_bank_name),expression:"attachment_1.client_bank_name"}],class:{
                    input__border:
                      _vm.messages &&
                      typeof _vm.messages['attachments.attachment_1.client_bank_name'] !==
                        'undefined',
                  },attrs:{"type":"text","required":"","name":"","placeholder":"Наименование  банка"},domProps:{"value":(_vm.attachment_1.client_bank_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.attachment_1, "client_bank_name", $event.target.value)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.attachment_1.client_bank_name),expression:"attachment_1.client_bank_name"}],class:{
                    input__border:
                      _vm.messages &&
                      typeof _vm.messages['attachments.attachment_1.client_bank_name_kz'] !==
                        'undefined',
                  },attrs:{"type":"text","required":"","name":"","placeholder":"Наименование  банка (на казахском)","value":""},domProps:{"value":(_vm.attachment_1.client_bank_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.attachment_1, "client_bank_name", $event.target.value)}}})]),(
                  _vm.messages &&
                  typeof _vm.messages['attachments.attachment_1.client_bank_name'] !==
                    'undefined' &&
                  !_vm.lang_type
                )?_c('div',{staticStyle:{"padding":"5px 15px","float":"left","color":"red"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.messages["attachments.attachment_1.client_bank_name"]))+" ")]):_vm._e(),(
                  _vm.messages &&
                  typeof _vm.messages['attachments.attachment_1.client_bank_name_kz'] !==
                    'undefined' &&
                  _vm.lang_type
                )?_c('div',{staticStyle:{"padding":"5px 15px","float":"left","color":"red"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.messages["attachments.attachment_1.client_bank_name_kz"]))+" ")]):_vm._e()])])])])]),_c('div',{staticClass:"agreement__line"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-lg-3"},[(!_vm.lang_type)?_c('div',{staticClass:"agreement__item--title"},[_vm._v(" Наименование Пользователя ")]):_c('div',{staticClass:"agreement__item--title"},[_vm._v(" Наименование Пользователя (на казахском) ")])]),_c('div',{staticClass:"col-lg-9"},[_c('div',{staticClass:"agreement__item--input"},[(!_vm.lang_type)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.attachment_1.client_name_user),expression:"attachment_1.client_name_user"}],class:{
                    input__border:
                      _vm.messages &&
                      typeof _vm.messages['attachments.attachment_1.client_name_user'] !==
                        'undefined',
                  },attrs:{"type":"text","required":"","placeholder":"Наименование Пользователя"},domProps:{"value":(_vm.attachment_1.client_name_user)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.attachment_1, "client_name_user", $event.target.value)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.attachment_1.client_name_user_kz),expression:"attachment_1.client_name_user_kz"}],class:{
                    input__border:
                      _vm.messages &&
                      typeof _vm.messages['attachments.attachment_1.client_name_user_kz'] !==
                        'undefined',
                  },attrs:{"type":"text","required":"","placeholder":"Наименование Пользователя (на казахском)  "},domProps:{"value":(_vm.attachment_1.client_name_user_kz)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.attachment_1, "client_name_user_kz", $event.target.value)}}})]),(
                  _vm.messages &&
                  typeof _vm.messages['attachments.attachment_1.client_name_user'] !==
                    'undefined' &&
                  !_vm.lang_type
                )?_c('div',{staticStyle:{"padding":"5px 15px","float":"left","color":"red"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.messages["attachments.attachment_1.client_name_user"]))+" ")]):_vm._e(),(
                  _vm.messages &&
                  typeof _vm.messages['attachments.attachment_1.client_name_user_kz'] !==
                    'undefined' &&
                  _vm.lang_type
                )?_c('div',{staticStyle:{"padding":"5px 15px","float":"left","color":"red"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.messages["attachments.attachment_1.client_name_user_kz"]))+" ")]):_vm._e()])])])])]),_c('div',{staticClass:"agreement__line"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-lg-3"},[(!_vm.lang_type)?_c('div',{staticClass:"agreement__item--title"},[_vm._v(" Наименование сокращенное ")]):_c('div',{staticClass:"agreement__item--title"},[_vm._v(" Наименование сокращенное (на казахском) ")])]),_c('div',{staticClass:"col-lg-9"},[_c('div',{staticClass:"agreement__item--input"},[(!_vm.lang_type)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.attachment_1.client_name_short),expression:"attachment_1.client_name_short"}],class:{
                    input__border:
                      _vm.messages &&
                      typeof _vm.messages['attachments.attachment_1.client_name_short'] !==
                        'undefined',
                  },attrs:{"type":"text","required":"","name":"","placeholder":"Наименование  сокращенное","value":""},domProps:{"value":(_vm.attachment_1.client_name_short)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.attachment_1, "client_name_short", $event.target.value)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.attachment_1.client_name_short_kz),expression:"attachment_1.client_name_short_kz"}],class:{
                    input__border:
                      _vm.messages &&
                      typeof _vm.messages['attachments.attachment_1.client_name_short_kz'] !==
                        'undefined',
                  },attrs:{"type":"text","required":"","name":"","placeholder":"  Наименование сокращенное (на казахском)","value":""},domProps:{"value":(_vm.attachment_1.client_name_short_kz)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.attachment_1, "client_name_short_kz", $event.target.value)}}})]),(
                  _vm.messages &&
                  typeof _vm.messages['attachments.attachment_1.client_name_short'] !==
                    'undefined' &&
                  !_vm.lang_type
                )?_c('div',{staticStyle:{"padding":"5px 15px","float":"left","color":"red"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.messages["attachments.attachment_1.client_name_short"]))+" ")]):_vm._e(),(
                  _vm.messages &&
                  typeof _vm.messages['attachments.attachment_1.client_name_short_kz'] !==
                    'undefined' &&
                  _vm.lang_type
                )?_c('div',{staticStyle:{"padding":"5px 15px","float":"left","color":"red"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.messages["attachments.attachment_1.client_name_short_kz"]))+" ")]):_vm._e()])])])])]),_c('div',{staticClass:"agreement__line"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"row align-items-center"},[_vm._m(7),_c('div',{staticClass:"col-lg-9"},[_c('div',{staticClass:"agreement__item--input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.attachment_1.client_bank_kbe),expression:"attachment_1.client_bank_kbe"}],class:{
                    input__border:
                      _vm.messages &&
                      typeof _vm.messages['attachments.attachment_1.client_bank_kbe'] !==
                        'undefined',
                  },attrs:{"type":"text","required":"","placeholder":"КБе"},domProps:{"value":(_vm.attachment_1.client_bank_kbe)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.attachment_1, "client_bank_kbe", $event.target.value)}}})]),(
                  _vm.messages &&
                  typeof _vm.messages['attachments.attachment_1.client_bank_kbe'] !==
                    'undefined'
                )?_c('div',{staticStyle:{"padding":"5px 15px","float":"left","color":"red"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.messages["attachments.attachment_1.client_bank_kbe"]))+" ")]):_vm._e()])])])])]),_c('hr',{staticClass:"line__hr"}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-9 offset-lg-3"},[_c('v-file-input',{attrs:{"placeholder":"","label":"Выберите файл","multiple":"","prepend-icon":"mdi-paperclip"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                var text = ref.text;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(" "+_vm._s(text)+" ")])]}}],null,false,590729088),model:{value:(_vm.images),callback:function ($$v) {_vm.images=$$v},expression:"images"}}),_c('button',{staticClass:"form__button",attrs:{"type":"submit"}},[_c('svg',{attrs:{"width":"19","height":"19","viewBox":"0 0 19 19","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M13.68 7.34667C13.7849 7.23411 13.842 7.08524 13.8393 6.93142C13.8366 6.7776 13.7742 6.63083 13.6655 6.52205C13.5567 6.41326 13.4099 6.35095 13.2561 6.34824C13.1023 6.34552 12.9534 6.40262 12.8408 6.5075L8.11458 11.2337L6.15917 9.27833C6.04661 9.17345 5.89774 9.11635 5.74392 9.11907C5.5901 9.12178 5.44334 9.1841 5.33455 9.29288C5.22576 9.40167 5.16345 9.54843 5.16074 9.70225C5.15802 9.85607 5.21512 10.0049 5.32 10.1175L7.695 12.4925C7.80633 12.6037 7.95724 12.6661 8.11458 12.6661C8.27193 12.6661 8.42284 12.6037 8.53417 12.4925L13.68 7.34667V7.34667Z","fill":"white"}}),_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M9.49996 0.791666C4.69058 0.791666 0.791626 4.69062 0.791626 9.5C0.791626 14.3094 4.69058 18.2083 9.49996 18.2083C14.3093 18.2083 18.2083 14.3094 18.2083 9.5C18.2083 4.69062 14.3093 0.791666 9.49996 0.791666ZM1.97913 9.5C1.97913 7.50535 2.7715 5.5924 4.18193 4.18197C5.59236 2.77154 7.50531 1.97917 9.49996 1.97917C11.4946 1.97917 13.4076 2.77154 14.818 4.18197C16.2284 5.5924 17.0208 7.50535 17.0208 9.5C17.0208 11.4946 16.2284 13.4076 14.818 14.818C13.4076 16.2285 11.4946 17.0208 9.49996 17.0208C7.50531 17.0208 5.59236 16.2285 4.18193 14.818C2.7715 13.4076 1.97913 11.4946 1.97913 9.5V9.5Z","fill":"white"}})]),_vm._v(" Отправить ")])],1)])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg-3"},[_c('div',{staticClass:"agreement__item--title"},[_vm._v("Почтовый индекс")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"agreement__item--title"},[_vm._v(" Контактный телефон первичного контакта ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"agreement__item--title"},[_vm._v("E-mail первичного контакта")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"agreement__item--title"},[_vm._v(" Контактный телефон вторичного контакта ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"agreement__item--title"},[_vm._v("E-mail вторичного контакта")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"agreement__item--title"},[_vm._v("Факс (первый)")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"agreement__item--title"},[_vm._v("Факс (второй)")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg-3"},[_c('div',{staticClass:"agreement__item--title"},[_vm._v("КБе")])])}]

export { render, staticRenderFns }