<template>
  <form @submit.prevent="createContract()" v-if="GET_USER_DATA">
    <hr class="line__hr" />

    <h3 class="mb-10">Заполнение приложение №1</h3>

    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title" v-if="!lang_type">ФИО,инициалы</div>
              <div class="agreement__item--title" v-else>ФИО,инициалы (на казахском)</div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text" required
                  v-if="!lang_type"
                  v-model="attachment_1.client_fio"
                  name=""
                  placeholder="ФИО,инициалы"
                  v-bind:class="{
                    input__border:
                      messages &&
                      typeof messages['attachments.attachment_1.client_fio'] !==
                        'undefined',
                  }"
                />
                <input
                  type="text" required
                  v-else
                  v-model="attachment_1.client_fio_kz"
                  placeholder="ФИО,инициалы (на казахском)"
                  v-bind:class="{
                    input__border:
                      messages &&
                      typeof messages['attachments.attachment_1.client_fio_kz'] !==
                        'undefined',
                  }"
                />
              </div>
              <div
                style="padding: 5px 15px; float: left; color: red"
                v-if="
                  messages &&
                  typeof messages['attachments.attachment_1.client_fio'] !==
                    'undefined' &&
                  !lang_type
                "
              >
                {{ $t(messages["attachments.attachment_1.client_fio"]) }}
              </div>
              <div
                style="padding: 5px 15px; float: left; color: red"
                v-if="
                  messages &&
                  typeof messages['attachments.attachment_1.client_fio_kz'] !==
                    'undefined' &&
                  lang_type
                "
              >
                {{ $t(messages["attachments.attachment_1.client_fio_kz"]) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title" v-if="!lang_type">
                Действующего на основании
              </div>
              <div class="agreement__item--title" v-else>
                Действующего на основании (на казахском)
              </div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text" required
                  v-if="!lang_type"
                  v-model="attachment_1.client_based_on"
                  name=""
                  placeholder="Действующего на основании"
                  v-bind:class="{
                    input__border:
                      messages &&
                      typeof messages['attachments.attachment_1.client_based_on'] !==
                        'undefined',
                  }"
                />
                <input
                  type="text" required
                  v-else
                  v-model="attachment_1.client_based_on_kz"
                  name=""
                  placeholder="Действующего на основании (на казахском)"
                  v-bind:class="{
                    input__border:
                      messages &&
                      typeof messages['attachments.attachment_1.client_based_on_kz'] !==
                        'undefined',
                  }"
                />
              </div>
              <div
                style="padding: 5px 15px; float: left; color: red"
                v-if="
                  messages &&
                  typeof messages['attachments.attachment_1.client_based_on'] !==
                    'undefined' &&
                  !lang_type
                "
              >
                {{ $t(messages["attachments.attachment_1.client_based_on"]) }}
              </div>
              <div
                style="padding: 5px 15px; float: left; color: red"
                v-if="
                  messages &&
                  typeof messages['attachments.attachment_1.client_based_on_kz'] !==
                    'undefined' &&
                  lang_type
                "
              >
                {{ $t(messages["attachments.attachment_1.client_based_on_kz"]) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title" v-if="!lang_type">
                Юридический и фактический адрес
              </div>
              <div class="agreement__item--title" v-else>
                Юридический и фактический адрес (на казахском)
              </div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  v-if="!lang_type"
                  v-model="attachment_1.client_address"
                  type="text"
                  placeholder="Юридический и фактический адрес"
                  v-bind:class="{
                    input__border:
                      messages &&
                      typeof messages['attachments.attachment_1.client_address'] !==
                        'undefined',
                  }"
                />
                <input
                  v-else
                  v-model="attachment_1.client_address_kz"
                  type="text"
                  placeholder="Юридический и фактический адрес (на казахском)"
                  v-bind:class="{
                    input__border:
                      messages &&
                      typeof messages['attachments.attachment_1.client_address_kz'] !==
                        'undefined',
                  }"
                />
              </div>
              <div
                style="padding: 5px 15px; float: left; color: red"
                v-if="
                  messages &&
                  typeof messages['attachments.attachment_1.client_address'] !==
                    'undefined' &&
                  !lang_type
                "
              >
                {{ $t(messages["attachments.attachment_1.client_address"]) }}
              </div>
              <div
                style="padding: 5px 15px; float: left; color: red"
                v-if="
                  messages &&
                  typeof messages['attachments.attachment_1.client_address_kz'] !==
                    'undefined' &&
                  lang_type
                "
              >
                {{ $t(messages["attachments.attachment_1.client_address_kz"]) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title">Почтовый индекс</div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text" required
                  v-model="attachment_1.client_postcode"
                  name=""
                  placeholder="Почтовый индекс"
                  v-bind:class="{
                    input__border:
                      messages &&
                      typeof messages['attachments.attachment_1.client_postcode'] !==
                        'undefined',
                  }"
                />
              </div>
              <div
                style="padding: 5px 15px; float: left; color: red"
                v-if="
                  messages &&
                  typeof messages['attachments.attachment_1.client_postcode'] !==
                    'undefined'
                "
              >
                {{ $t(messages["attachments.attachment_1.client_postcode"]) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-6">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="agreement__item--title">
                Контактный телефон первичного контакта
              </div>
            </div>
            <div class="col-lg-6">
              <div class="agreement__item--input">
                <input
								v-mask="{mask: '+7 (999) 999 99 99'}"
                  type="text" required
                  v-model="attachment_1.client_first_phone_number"
                  placeholder="Контактный телефон первичного контакта"
                  v-bind:class="{
                    input__border:
                      messages &&
                      typeof messages[
                        'attachments.attachment_1.client_first_phone_number'
                      ] !== 'undefined',
                  }"
                />
              </div>
              <div
                style="padding: 5px 15px; float: left; color: red"
                v-if="
                  messages &&
                  typeof messages[
                    'attachments.attachment_1.client_first_phone_number'
                  ] !== 'undefined'
                "
              >
                {{ $t(messages["attachments.attachment_1.client_first_phone_number"]) }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="agreement__item--title">E-mail первичного контакта</div>
            </div>
            <div class="col-lg-6">
              <div class="agreement__item--input">
                <input
                  type="email" required
                  v-model="attachment_1.client_first_email"
                  name=""
                  placeholder="E-mail первичного контакта"
                  v-bind:class="{
                    input__border:
                      messages &&
                      typeof messages['attachments.attachment_1.client_first_email'] !==
                        'undefined',
                  }"
                />
              </div>
              <div
                style="padding: 5px 15px; float: left; color: red"
                v-if="
                  messages &&
                  typeof messages['attachments.attachment_1.client_first_email'] !==
                    'undefined'
                "
              >
                {{ $t(messages["attachments.attachment_1.client_first_email"]) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-6">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="agreement__item--title">
                Контактный телефон вторичного контакта
              </div>
            </div>
            <div class="col-lg-6">
              <div class="agreement__item--input">
                <input
								v-mask="{mask: '+7 (999) 999 99 99'}"
                  type="text" required
                  name=""
                  v-model="attachment_1.client_second_phone_number"
                  placeholder="Контактный телефон вторичного контакта"
                  v-bind:class="{
                    input__border:
                      messages &&
                      typeof messages[
                        'attachments.attachment_1.client_second_phone_number'
                      ] !== 'undefined',
                  }"
                />
              </div>
              <div
                style="padding: 5px 15px; float: left; color: red"
                v-if="
                  messages &&
                  typeof messages[
                    'attachments.attachment_1.client_second_phone_number'
                  ] !== 'undefined'
                "
              >
                {{ $t(messages["attachments.attachment_1.client_second_phone_number"]) }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="agreement__item--title">E-mail вторичного контакта</div>
            </div>
            <div class="col-lg-6">
              <div class="agreement__item--input">
                <input
                  type="text" required
                  name=""
                  v-model="attachment_1.client_second_email"
                  placeholder="E-mail вторичного контакта"
                  v-bind:class="{
                    input__border:
                      messages &&
                      typeof messages['attachments.attachment_1.client_second_email'] !==
                        'undefined',
                  }"
                />
              </div>
              <div
                style="padding: 5px 15px; float: left; color: red"
                v-if="
                  messages &&
                  typeof messages['attachments.attachment_1.client_second_email'] !==
                    'undefined'
                "
              >
                {{ $t(messages["attachments.attachment_1.client_second_email"]) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-6">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="agreement__item--title">Факс (первый)</div>
            </div>
            <div class="col-lg-6">
              <div class="agreement__item--input">
                <input
								v-mask="{mask: '+7 (999) 999 99 99'}"
                  type="text" required
                  name=""
                  v-model="attachment_1.client_first_fax"
                  placeholder="Факс (первый)"
                  v-bind:class="{
                    input__border:
                      messages &&
                      typeof messages['attachments.attachment_1.client_first_fax'] !==
                        'undefined',
                  }"
                />
              </div>
              <div
                style="padding: 5px 15px; float: left; color: red"
                v-if="
                  messages &&
                  typeof messages['attachments.attachment_1.client_first_fax'] !==
                    'undefined'
                "
              >
                {{ $t(messages["attachments.attachment_1.client_first_fax"]) }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="agreement__item--title">Факс (второй)</div>
            </div>
            <div class="col-lg-6">
              <div class="agreement__item--input">
                <input
								v-mask="{mask: '+7 (999) 999 99 99'}"
                  type="text" required
                  name=""
                  v-model="attachment_1.client_second_fax"
                  placeholder="Факс (второй)"
                  v-bind:class="{
                    input__border:
                      messages &&
                      typeof messages['attachments.attachment_1.client_second_fax'] !==
                        'undefined',
                  }"
                />
              </div>
              <div
                style="padding: 5px 15px; float: left; color: red"
                v-if="
                  messages &&
                  typeof messages['attachments.attachment_1.client_second_fax'] !==
                    'undefined'
                "
              >
                {{ $t(messages["attachments.attachment_1.client_second_fax"]) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title">ИИК</div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text" required
                  v-model="attachment_1.client_bank_iik"
                  name=""
                  placeholder="ИИК"
                  v-bind:class="{
                    input__border:
                      messages &&
                      typeof messages['attachments.attachment_1.client_bank_iik'] !==
                        'undefined',
                  }"
                />
              </div>
              <div
                style="padding: 5px 15px; float: left; color: red"
                v-if="
                  messages &&
                  typeof messages['attachments.attachment_1.client_bank_iik'] !==
                    'undefined'
                "
              >
                {{ $t(messages["attachments.attachment_1.client_bank_iik"]) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title" v-if="!lang_type">
                Наименование организации
              </div>
              <div class="agreement__item--title" v-else>
                Наименование организации (на казахском)
              </div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text" required
                  v-if="!lang_type"
                  v-model="attachment_1.client_bank_name"
                  name=""
                  placeholder="Наименование  банка"
                  v-bind:class="{
                    input__border:
                      messages &&
                      typeof messages['attachments.attachment_1.client_bank_name'] !==
                        'undefined',
                  }"
                />
                <input
                  type="text" required
                  v-else
                  v-model="attachment_1.client_bank_name"
                  name=""
                  placeholder="Наименование  банка (на казахском)"
                  value=""
                  v-bind:class="{
                    input__border:
                      messages &&
                      typeof messages['attachments.attachment_1.client_bank_name_kz'] !==
                        'undefined',
                  }"
                />
              </div>
              <div
                style="padding: 5px 15px; float: left; color: red"
                v-if="
                  messages &&
                  typeof messages['attachments.attachment_1.client_bank_name'] !==
                    'undefined' &&
                  !lang_type
                "
              >
                {{ $t(messages["attachments.attachment_1.client_bank_name"]) }}
              </div>
              <div
                style="padding: 5px 15px; float: left; color: red"
                v-if="
                  messages &&
                  typeof messages['attachments.attachment_1.client_bank_name_kz'] !==
                    'undefined' &&
                  lang_type
                "
              >
                {{ $t(messages["attachments.attachment_1.client_bank_name_kz"]) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title" v-if="!lang_type">
                Наименование Пользователя
              </div>
              <div class="agreement__item--title" v-else>
                Наименование Пользователя (на казахском)
              </div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text" required
                  v-if="!lang_type"
                  v-model="attachment_1.client_name_user"
                  placeholder="Наименование Пользователя"
                  v-bind:class="{
                    input__border:
                      messages &&
                      typeof messages['attachments.attachment_1.client_name_user'] !==
                        'undefined',
                  }"
                />
                <input
                  type="text" required
                  v-else
                  v-model="attachment_1.client_name_user_kz"
                  placeholder="Наименование Пользователя (на казахском)  "
                  v-bind:class="{
                    input__border:
                      messages &&
                      typeof messages['attachments.attachment_1.client_name_user_kz'] !==
                        'undefined',
                  }"
                />
              </div>
              <div
                style="padding: 5px 15px; float: left; color: red"
                v-if="
                  messages &&
                  typeof messages['attachments.attachment_1.client_name_user'] !==
                    'undefined' &&
                  !lang_type
                "
              >
                {{ $t(messages["attachments.attachment_1.client_name_user"]) }}
              </div>
              <div
                style="padding: 5px 15px; float: left; color: red"
                v-if="
                  messages &&
                  typeof messages['attachments.attachment_1.client_name_user_kz'] !==
                    'undefined' &&
                  lang_type
                "
              >
                {{ $t(messages["attachments.attachment_1.client_name_user_kz"]) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title" v-if="!lang_type">
                Наименование сокращенное
              </div>
              <div class="agreement__item--title" v-else>
                Наименование сокращенное (на казахском)
              </div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text" required
                  v-if="!lang_type"
                  v-model="attachment_1.client_name_short"
                  name=""
                  placeholder="Наименование  сокращенное"
                  value=""
                  v-bind:class="{
                    input__border:
                      messages &&
                      typeof messages['attachments.attachment_1.client_name_short'] !==
                        'undefined',
                  }"
                />
                <input
                  type="text" required
                  v-else
                  v-model="attachment_1.client_name_short_kz"
                  name=""
                  placeholder="  Наименование сокращенное (на казахском)"
                  value=""
                  v-bind:class="{
                    input__border:
                      messages &&
                      typeof messages['attachments.attachment_1.client_name_short_kz'] !==
                        'undefined',
                  }"
                />
              </div>
              <div
                style="padding: 5px 15px; float: left; color: red"
                v-if="
                  messages &&
                  typeof messages['attachments.attachment_1.client_name_short'] !==
                    'undefined' &&
                  !lang_type
                "
              >
                {{ $t(messages["attachments.attachment_1.client_name_short"]) }}
              </div>
              <div
                style="padding: 5px 15px; float: left; color: red"
                v-if="
                  messages &&
                  typeof messages['attachments.attachment_1.client_name_short_kz'] !==
                    'undefined' &&
                  lang_type
                "
              >
                {{ $t(messages["attachments.attachment_1.client_name_short_kz"]) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title">БИК</div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text" required
                  name=""
                  v-model="attachment_1.client_bank_bik"
                  placeholder="БИК  "
                  value=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title">КБе</div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text" required
                  v-model="attachment_1.client_bank_kbe"
                  placeholder="КБе"
                  v-bind:class="{
                    input__border:
                      messages &&
                      typeof messages['attachments.attachment_1.client_bank_kbe'] !==
                        'undefined',
                  }"
                />
              </div>
              <div
                style="padding: 5px 15px; float: left; color: red"
                v-if="
                  messages &&
                  typeof messages['attachments.attachment_1.client_bank_kbe'] !==
                    'undefined'
                "
              >
                {{ $t(messages["attachments.attachment_1.client_bank_kbe"]) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr class="line__hr" />

    <div class="row">
      <div class="col-lg-9 offset-lg-3">
        <v-file-input
          v-model="images"
          placeholder=""
          label="Выберите файл"
          multiple
          prepend-icon="mdi-paperclip"
        >
          <template v-slot:selection="{ text }">
            <v-chip small label color="primary">
              {{ text }}
            </v-chip>
          </template>
        </v-file-input>
        <button type="submit" class="form__button">
          <svg
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.68 7.34667C13.7849 7.23411 13.842 7.08524 13.8393 6.93142C13.8366 6.7776 13.7742 6.63083 13.6655 6.52205C13.5567 6.41326 13.4099 6.35095 13.2561 6.34824C13.1023 6.34552 12.9534 6.40262 12.8408 6.5075L8.11458 11.2337L6.15917 9.27833C6.04661 9.17345 5.89774 9.11635 5.74392 9.11907C5.5901 9.12178 5.44334 9.1841 5.33455 9.29288C5.22576 9.40167 5.16345 9.54843 5.16074 9.70225C5.15802 9.85607 5.21512 10.0049 5.32 10.1175L7.695 12.4925C7.80633 12.6037 7.95724 12.6661 8.11458 12.6661C8.27193 12.6661 8.42284 12.6037 8.53417 12.4925L13.68 7.34667V7.34667Z"
              fill="white"
            ></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.49996 0.791666C4.69058 0.791666 0.791626 4.69062 0.791626 9.5C0.791626 14.3094 4.69058 18.2083 9.49996 18.2083C14.3093 18.2083 18.2083 14.3094 18.2083 9.5C18.2083 4.69062 14.3093 0.791666 9.49996 0.791666ZM1.97913 9.5C1.97913 7.50535 2.7715 5.5924 4.18193 4.18197C5.59236 2.77154 7.50531 1.97917 9.49996 1.97917C11.4946 1.97917 13.4076 2.77154 14.818 4.18197C16.2284 5.5924 17.0208 7.50535 17.0208 9.5C17.0208 11.4946 16.2284 13.4076 14.818 14.818C13.4076 16.2285 11.4946 17.0208 9.49996 17.0208C7.50531 17.0208 5.59236 16.2285 4.18193 14.818C2.7715 13.4076 1.97913 11.4946 1.97913 9.5V9.5Z"
              fill="white"
            ></path>
          </svg>
          Отправить
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from "vuex";
import Vue from 'vue';

const VueInputMask = require('vue-inputmask').default
Vue.use(VueInputMask)
export default {
  props: ["contract_title", "document_id", "type", "attach_id", "lang_type"],
  data() {
    return {
      attachment: "",
      messageColor: null,
      message: "",
      images: [],
      attachment_1: {
        client_based_on: "Устава",
        client_based_on_kz: "Жарғы",
        client_address: "",
        client_postcode: "",
      },
      messages: [],
    };
  },
  created() {},
  methods: {
    getAttachment(id) {
      this.$axios({
        method: "get",
        url: this.$API_URL + this.$API_VERSION + "user/attachment?id=" + id,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
      })
        .then((response) => {
          this.attachment_1 = response.data.fields;
        })
        .catch((error) => {
          console.warn(error);
        });
    },
    createContract() {
      let contractForm = new FormData();
      let url = "user/documents/fasti";

      if (this.attach_id) {
        contractForm.append("attachment_id", this.attach_id);
        contractForm.append("document_id", this.document_id);
        contractForm.append("type", this.type);
        url = "user/update/attachment";
      }

      for (var key in this.attachment_1) {
        contractForm.append(
          "attachments[attachment_1][" + key + "]",
          this.attachment_1[key]
        );
      }

      for (var i = 0; i < this.images.length; i++) {
        contractForm.append("documents[]", this.images[i]);
      }
      this.$axios
        .post(this.$API_URL + this.$API_VERSION + url, contractForm, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.$toast.open({
            message: "Успешно заполнено",
            type: "success",
            position: "bottom",
            duration: 4000,
            queue: true,
          });

          this.$router.push("/");
        })
        .catch((error) => {
					if (error.response.data.message) {
						this.$toast.open({
              message: error.response.data.message,
              type: "error",
              position: "bottom",
              duration: 4000,
              queue: true,
            });
					}
          this.$toast.open({
            message: "Заполните все поля на русском и казахском",
            type: "error",
            position: "bottom",
            duration: 4000,
            queue: true,
          });
          this.messages = [];
          for (let key in error.response.data.errors) {
            this.messages[key] = error.response.data.errors[key].pop();
          }
        });
    },
  },
  mounted() {
    if (!this.attach_id) {
      this.attachment_1.client_fio =
        this.GET_USER_DATA.common_name + " " + this.GET_USER_DATA.last_name;
      this.attachment_1.client_signer_in = this.GET_USER_DATA.role.name_ru;
      this.attachment_1.client_signer_in_kz = this.GET_USER_DATA.role.name_kz;
      this.attachment_1.client_bank_iik = this.GET_USER_DATA.organization.iik;
      this.attachment_1.client_bank_bik = this.GET_USER_DATA.organization.bik;
      this.attachment_1.client_first_phone_number =
        this.GET_USER_DATA.organization.phone == null
          ? ""
          : this.GET_USER_DATA.organization.phone;
      this.attachment_1.client_first_email = this.GET_USER_DATA.organization.email;
      this.attachment_1.client_bank_name = this.GET_USER_DATA.organization.name;
      this.attachment_1.client_address = this.GET_USER_DATA.organization.address;
      // this.attachment_1.client_address = this.GET_USER_DATA.organization.address!="null"? this.GET_USER_DATA.organization.address:'';
    } else {
      this.getAttachment(this.$route.params.attach_id);
    }
  },
  watch: {
    attachment(val) {
      console.log(val);
      this.attachment_1 = val.fields;
    },
  },
  computed: {
    ...mapGetters(["GET_TOKEN", "GET_USER_DATA"]),
  },
};
</script>
